<template>
  <moe-page title="九宫格抽奖">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">九宫格抽奖</div>
          </div>
        </div>
      </template>

      <moe-inquire @search="luckyGridSearch">
        <el-form-item label="状态">
          <moe-select type="luckyGridStateList" v-model="luckyGridParams.state" placeholder="选择状态"></moe-select>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model.trim="luckyGridParams.name" placeholder="输入活动名称" maxlength="50" clearable />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="luckyGridTableRef" url="/shop/lottery/list" :params="luckyGridParams" :numberShow="false">
        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/lotteryManage/luckyGrid/add')">新建抽奖</el-button>
        <el-table-column label="活动编号" prop="id" min-width="80" />
        <el-table-column label="活动名称" prop="name" min-width="120" :show-overflow-tooltip="true" />
        <el-table-column label="活动时间" min-width="310">
          <div class="df fdc aic jcc" slot-scope="{ row }">
            <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
            <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
          </div>
        </el-table-column>
        <el-table-column label="状态" min-width="150">
          <div :class="$moe_format.getLuckyGridStateListColor(row.state)" slot-scope="{ row }">
            {{ $moe_format.getLuckyGridStateList(row.state) }}
          </div>
        </el-table-column>
        <el-table-column label="抽奖人数" prop="drawCount" min-width="80" />
        <el-table-column label="中奖次数" prop="winCount" min-width="80" />
        <el-table-column label="操作" fixed="right" width="400">
          <div class="moe-table_btns" slot-scope="{ row }">
            <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/lotteryManage/luckyGrid/detail`, { id: row.id })">查看</el-button>
            <template v-if="!['FINISHED'].includes(row.state)">
              <el-button type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/lotteryManage/luckyGrid/add`, { id: row.id })">编辑</el-button>
              <el-button type="danger" size="mini" icon="el-icon-circle-close" @click="handleClose(row)">关闭</el-button>
            </template>
            <el-button v-if="!['PENDING'].includes(row.state)" type="warning" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/lotteryManage/luckyGrid/record`, { id: row.id })">抽奖记录</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'LotteryManageLuckyGridList',
  data() {
    return {
      luckyGridParams: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        state: '',
      }
    }
  },
  methods: {
    luckyGridSearch(isSearch) {
      if (!isSearch) {
        this.luckyGridParams = {
          pageNum: 1,
          pageSize: 10,
          name: '',
          state: '',
        }
      }

      this.$refs['luckyGridTableRef'].searchData();
    },
    async handleClose({ name, id }) {
      this.$moe_layer.confirm(`您确定要关闭当前选项"${name}"吗？`, async () => {
        const { code, message } = await this.$moe_api.LUCKYGRID_API.closeLottery({ id });
        if (code === 200) {
          this.$moe_msg.success('关闭成功');
          this.luckyGridSearch(false);
        } else {
          this.$moe_msg.error(message);
        }
      });
    },
  }
}
</script>